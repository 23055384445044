import React, { useEffect, useMemo } from "react";
// hooks
import { useGetCatalogueApps } from "../../customHooks/apps/useApps";
// Models
import { AppModel } from "../../models/app";
// context
import { useParams } from "react-router-dom";
// components
import IframePage from "../Iframe/IframePage";
// maui
import { Spin } from "maui";
// helpers
import { decodeString, useUrlParams } from "findem-helpers";
// constants
import { DUMMY_APPS } from "../../constants/mockData/catalogueApps";
// types
import { IApp } from "../../types/app";

const InstalledApp: React.FC = () => {
  const { id: paramId } = useParams<{ id?: string }>();
  const { url } = useUrlParams();

  const [launchedApp, setLaunchedApp] = React.useState<AppModel | null>(null);

  // camalize the id to match react component example: cred-builder -> CredBuilder
  // Render the component based on the id and if iframe url is not provided
  // const appComponentStr = paramId?.split('-').map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join('');
  // const appComponent = appComponentStr ? require(`../${appComponentStr}/${appComponentStr}`).default : null;

  const appComponent: React.FC | null = React.useMemo(() => {
    const _appId = paramId?.replaceAll("-", "_");
    const _app = DUMMY_APPS.find((app: IApp) => app.id === _appId);
    return _app?.component ? (_app.component as React.FC) : null;
  }, [paramId]);

  const iframeUrl = useMemo(() => {
    if (url) {
      const isStaging = window.location.hostname.includes("dev");
      return isStaging
        ? `https://rra-staging.findem.ai${url}`
        : `https://rra-app.findem.ai${url}`;
    }
    return launchedApp?.getValue().launchUrl;
  }, [launchedApp, url]);

  const { isLoading, apps } = useGetCatalogueApps();

  useEffect(() => {
    if (apps && paramId) {
      const app = apps.find((app: AppModel) => app.getValue().id === paramId);
      if (app) {
        setLaunchedApp(app);
      }
    }
  }, [apps, paramId]);

  // add post message listener to handle sidebar collapse
  React.useLayoutEffect(() => {
    window.addEventListener("message", (event) => {
      if (
        event.data?.type === "searchPageMsg" ||
        event.data?.type === "searchesPageMsg"
      ) {
        // add url query param to main page
        const { pathName } = event.data;
        if (pathName) {
          const url = new URL(window.location.href);
          url.searchParams.set("url", decodeString(pathName));
          window.history.pushState({}, "", decodeString(url.toString()));
        }
      }
    });
  }, []);

  return (
    <>
      {!isLoading && iframeUrl && <IframePage iframeRoute={iframeUrl} />}
      {appComponent && <>{React.createElement(appComponent)}</>}
      {isLoading && (
        <div>
          <Spin />
        </div>
      )}
    </>
  );
};

export default InstalledApp;
